<template>
  <div class="container">
    <div class="content">
      <img class="logo" src="../assets/images/pc/login-logo-icon.png" />
      <img class="close-btn" src="../assets/images/pc/close-icon.png" @click="$router.go(-1);" />
      <div class="title-item">手机号登录</div>
      <div class="input-item">
        <div class="area">+86</div>
        <input type="number" v-model="phone" maxlength="11" placeholder="输入注册偶遇圈手机号" />
      </div>
      <div class="input-item">
        <input v-model="vcode" placeholder="验证码" />
        <div class="code-btn" :class="phone ? '' : 'not'" @click="handleSendsms">{{ time > 0 ? time + 's' : '获取验证码' }}</div>
      </div>
      <div class="login-btn" :class="phone && vcode ? '' : 'not'" @click="handleLogin">登录</div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import { sendsms, login, userInfo } from '@/api/index'
export default {
  name: 'Login',
  data () {
    return {
      openid: '',
      phone: '',
      vcode: '',
      time: 0,
      submit: false
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    yqm: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['token'])
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_RANT', 'SET_USERINFO']),
    handleGetUserInfo () {
      userInfo().then(res => {
        if (res.error === 0) {
          this.SET_USERINFO(res.info)
          this.$dialog.alert({
            message: '登录成功'
          }).then(() => {
            this.$emit('handleHideLoginPopup')
          })
        } else {
          this.SET_TOKEN('')
          this.$dialog.alert({
            message: '登录失败, 请重试'
          })
        }
      })
    },
    handleSendsms () {
      const { phone, time, submit } = this
      if (time > 0 || submit) {
        return
      }
      const reg = /^1\d{10}$/
      if (!reg.test(phone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      this.submit = true
      sendsms({
        phone
      }).then(res => {
        this.submit = false
        if (res.error === 0) {
          let time = 60
          this.time = time
          const timer = setInterval(() => {
            time--
            this.time = time
            if (time <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$toast(res.info)
        }
      })
    },
    handleLogin () {
      const { phone, vcode, submit, openid, yqm } = this
      if (submit) {
        return
      }
      const reg = /^1\d{10}$/
      if (!reg.test(phone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (!vcode || vcode.length !== 4) {
        this.$toast('请输入正确的验证码')
        return
      }
      this.submit = true
      const params = {
        phone,
        vcode,
        device: 3,
        is_from: 4
      }
      if (openid) {
        params.open_id = openid
      }
      if (yqm) {
        params.yqm = yqm
        params.parent_type = 1
      }
      login(params).then(res => {
        this.submit = false
        if (res.error === 0) {
          this.SET_TOKEN(res.info)
          this.SET_RANT(res.rant)
          this.handleGetUserInfo()
        } else {
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, .7);
  .content {
    width: 542rem;
    height: 594rem;
    background-color: #fff;
    border-radius: 20rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -297rem 0 0 -276rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo {
      width: 280rem;
      height: 138rem;
      position: absolute;
      top: -32rem;
      left: 50%;
      margin-left: -140rem;
    }
    .close-btn {
      width: 28rem;
      height: 28rem;
      position: absolute;
      top: 18rem;
      right: 22rem;
    }
    .title-item {
      margin-top: 120rem;
      margin-bottom: 40rem;
      font-size: 36rem;
      color: #000;
    }
    .input-item {
      width: 464rem;
      height: 80rem;
      border-radius: 40rem;
      background-color: #f3f3f3;
      margin-bottom: 24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .area {
        padding: 0 20rem 0 30rem;
        font-size: 26rem;
        color: #333;
        border-right: 2rem solid #bdbdbd;
      }
      input {
        flex: 1;
        height: 100%;
        padding-left: 30rem;
        font-size: 26rem;
        color: #333;
      }
      .code-btn {
        width: 170rem;
        height: 62rem;
        line-height: 62rem;
        text-align: center;
        background-color: #010101;
        border-radius: 31rem;
        color: #fff;
        font-size: 26rem;
        margin-right: 6rem;
        &.foc {
          color: #fed300;
        }
        &.not {
          background-color: #bfbfbf;
        }
      }
    }
    .login-btn {
      margin-top: 26rem;
      width: 312rem;
      height: 80rem;
      border-radius: 40rem;
      background-color: #000;
      color: #fff;
      line-height: 80rem;
      text-align: center;
      font-size: 26rem;
      &.not {
        background-color: #bfbfbf;
      }
    }
  }
}
</style>
