<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <div class="page-head-item">
        <img src="../assets/images/dt-detail-head-img.png" @click="handleToApp" />
      </div>
      <!-- 占位 -->
      <div class="page-head-container"></div>
      <div v-if="userData.id" class="user-info-item">
        <div class="photo-list-item">
          <van-swipe id="mySwipe" ref="mySwipe" v-if="userData.photo && userData.photo.length > 0" class="my-swipe" indicator-color="white" @change="handleSwipeChange">
            <van-swipe-item v-for="(item, index) in userData.photo" :key="index">
              <div class="img">
                <div v-if="item.red_money > 0" class="red">
                  <img src="../assets/images/user_red_icon.png" />
                  红包作品
                </div>
                <template v-else>
                  <img :src="item.img" :class="item.red_money <= 0 && item.is_fire == 1 ? 'blur' : ''" />
                  <img v-if="item.type == 2" class="play" src="../assets/images/user_play_icon.png" />
                  <div v-if="item.red_money <= 0 && item.is_fire == 1" class="fire">
                    <img src="../assets/images/user_fire_icon.png" />
                    阅后即焚
                  </div>
                </template>
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator" id="customIndicator" ref="customIndicator">
                <div :ref="'indicator' + index" v-for="(item, index) in userData.photo" :key="index" class="indicator-img" :class="photoIndex == index ? 'active' : ''" @click="handleChangePhotoIndex(index)">
                  <div v-if="item.red_money > 0" class="red"></div>
                  <img v-else :src="item.img" :class="item.red_money <= 0 && item.is_fire == 1 ? 'blur' : ''" />
                  <img v-if="item.type == 2" class="play" src="../assets/images/user_play_icon.png" />
                  <div v-if="userData.real_type == 1" class="real">真人</div>
                  <img v-if="item.red_money <= 0 && item.is_fire == 1" class="fire" src="../assets/images/user_fire_icon.png" />
                </div>
              </div>
            </template>
          </van-swipe>
        </div>
        <div class="user-info-detail">
          <div class="nickname-item">
            <div class="nickname">{{ userData.nickname }}</div>
            <div class="flex">
              <img v-if="userData.isVip" src="../assets/images/user_vip_icon.png" />
            </div>
            <img class="like" src="../assets/images/user_like_icon.png" />
          </div>
          <div v-if="userData.ns_type == 2" class="real-item"><img src="../assets/images/circle_tag_icon_1.png" />国际友人</div>
          <div v-else-if="userData.ns_type == 1" class="real-item"><img src="../assets/images/circle_tag_icon_3.png" />女神认证</div>
          <div v-else-if="userData.real_type == 1" class="real-item"><img src="../assets/images/circle_tag_icon_2.png" />真人认证</div>
          <div class="job-item">
            <div class="job">{{ userData.current_address }} / {{ userData.sex == 1 ? '男' : '女' }} / {{ userData.birthday | calcAge() }}</div>
            <div v-if="userData.is_online == 1" class="status">在线</div>
            <div v-else class="status-not">{{ userData.last_time | calcLastTime() }}</div>
          </div>
          <div class="jj-item">
            <img class="icon" src="../assets/images/user_cite_icon.png" />
            <div class="jj">{{ userData.jj }}</div>
            <div class="tag-item">
              <div class="tag"><img src="../assets/images/user_height_icon.png" />{{ userData.height }}cm</div>
              <div class="tag"><img src="../assets/images/user_weight_icon.png" />{{ userData.weight }}kg</div>
              <div class="tag"><img src="../assets/images/user_job_icon.png" />{{ userData.c_name }}</div>
              <div class="tag"><img src="../assets/images/user_qz_icon.png" />{{ userData.q_name }}</div>
              <div class="tags">
                <img src="../assets/images/user_scope_icon.png" />
                <div class="name">活动范围</div>
                <div class="value">{{ userData.scopeText }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-container">
        <div class="icon"><img src="../assets/images/user_empty_icon.png" /></div>
        <div class="info">圈友找不到了</div>
      </div>
      <div class="open-button" @click="handleToApp">偶遇圈APP内打开</div>
    </div>
    <login-component :showPopup="showPopup" v-if="showPopup" @handleHideLoginPopup="handleHideLoginPopup"></login-component>
    <div v-if="showWechatTip" class="show-tip-container">
      <wechat-browser-tips-component @handleHideWechatBrowserTips="handleHideWechatBrowserTips"></wechat-browser-tips-component>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { userDetail, getSetting } from '@/api/index'
import Loading from '../components/Loading.vue'
import LoginComponent from '../components/LoginComponent.vue'
import WechatBrowserTipsComponent from '../components/WechatBrowserTipsComponent.vue'

export default {
  name: 'Friend',
  components: {
    Loading,
    LoginComponent,
    WechatBrowserTipsComponent
  },
  data () {
    return {
      id: 0,
      userData: {},
      photoIndex: 0,
      pageLoading: true,
      system: '',
      upAppInfo: {},
      showPopup: false,
      showWechatTip: false,
      isWechat: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.id = to.query.id
    })
  },
  computed: {
    ...mapState(['token'])
  },
  filters: {
    calcAge (time) {
      var nowDate = new Date()
      var birthdayDate = new Date(time * 1000)
      var age = nowDate.getFullYear() - birthdayDate.getFullYear()
      if (nowDate.getMonth() < birthdayDate.getMonth()) {
        age--
      } else if (nowDate.getMonth() == birthdayDate.getMonth() && nowDate.getDate() < birthdayDate.getDate()) {
        age--
      }
      return age + '岁'
    },
    calcLastTime (time) {
      var nowTime = (new Date().getTime()) / 1000
      var lastTime = '';
      if (nowTime - time > 31536000) {
        lastTime = '1年前';
      } else if (nowTime - time > 2592000) {
        lastTime = '1月前';
      } else if (nowTime - time > 604800) {
        lastTime = '1周前';
      } else if (nowTime - time > 86400) {
        lastTime = '1天前';
      } else if (nowTime - time > 3600) {
        lastTime = '1小时前';
      }
      return lastTime
    }
  },
  mounted() {
    this.handleGetUserDetail()
    this.checkSystem()
    this.handleGetSetting()
  },
  methods: {
    handleHideWechatBrowserTips () {
      this.showWechatTip = false
    },
    handleToApp () {
      if (this.token) {
        if (this.isWechat) {
          this.showWechatTip = true
        } else {
          this.handleDownloadApp()
        }
      } else {
        this.handleShowLoginPopup()
      }
    },
    checkSystem () {
      const ua = navigator.userAgent
      const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
      if (ua.toLowerCase().indexOf('micromessenger') > -1) {
        this.isWechat = true
      } else {
        this.isWechat = false
      }
      if (isiOS) {
        this.system = 'ios'
      } else if (isAndroid) {
        this.system = 'android'
      } else {
        this.system = 'pc'
      }
    },
    handleGetSetting () {
      getSetting({key: 'up_app'}).then(res => {
        try {
          this.upAppInfo = JSON.parse(res.info)
        } catch (error) {
          this.upAppInfo = res.info
        }
      })
    },
    handleDownloadApp () {
      const { upAppInfo, system } = this
      if (system === 'ios') {
        window.location.href = upAppInfo.ios_down_url
      }
      if (system === 'android') {
        window.location.href = upAppInfo.android_down_url
      }
    },
    handleShowLoginPopup () {
      this.showPopup = true
    },
    handleHideLoginPopup () {
      this.showPopup = false
    },
    handleGetUserDetail () {
      const { id } = this
      userDetail({id: id}).then((res) => {
        console.log(res.info)
        if (res.info.id) {
          var nowTime = (new Date().getTime()) / 1000;
          res.info.isVip = res.info.vip_end > 0 && res.info.vip_end > nowTime
          var scopeText = ''
          for (let index = 0; index < res.info.scope.length; index++) {
            const item = res.info.scope[index];
            if (index == 0) {
              scopeText = item.name;
            } else {
              scopeText += "/" + item.name;
            }
          }
          res.info.scopeText = scopeText
          document.title = res.info.nickname + " 的个人主页"
        } else {
          document.title = "圈友 的个人主页"
        }
        this.userData = res.info
        this.pageLoading = false
      })
    },
    handleSwipeChange (index) {
      this.photoIndex = index
      var w = this.$refs.indicator1[0].offsetWidth
      w =  w / 88 * 14 + w
      // if (index > 2) {
        this.$refs.customIndicator.scrollLeft = (index - 2) * w
      // }
    },
    handleChangePhotoIndex (index) {
      this.photoIndex = index
      this.$refs.mySwipe.swipeTo(index,{
        immediate: false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .flex {
    flex: 1;
  }
  .page-head-item {
    width: 100%;
    height: 128rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .page-head-container {
    width: 100%;
    height: 128rem;
  }
  .user-info-item {
    width: 100%;
    .photo-list-item {
      width: 100%;
      height: 848rem;
      position: relative;
      .my-swipe {
        width: 100%;
        height: 100%;
        .img {
          width: 100%;
          height: 848rem;
          overflow: hidden;
          position: relative;
          img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              &.blur {
                filter: blur(4rem);
              }
            }
            .play {
              width: 112rem;
              height: 112rem;
              position: absolute;
              top: 50%;
              left: 50%;
              margin: -56rem 0 0 -56rem;
            }
            .red,
            .fire {
              position: absolute;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              color: #fff;
              font-weight: 600;
              font-size: 32rem;
              img {
                width: 112rem;
                height: 112rem;
                margin-bottom: 24rem;
              }
            }
            .red {
              top: 0;
              left: 0;
              height: 100%;
              background-color: #f55e55;
            }
            .fire {
              top: 340rem;
              left: 50%;
              transform: translateX(-50%);
            }
        }
        .custom-indicator {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          white-space: nowrap;
          overflow-x: auto;
          padding: 52rem 0;
          background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.7));
          &::-webkit-scrollbar {
            width: 0;
          }
          .indicator-img {
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            width: 88rem;
            height: 88rem;
            border-width: 2rem;
            border-style: solid;
            border-color: transparent;
            border-radius: 12rem;
            overflow: hidden;
            margin-right: 14rem;
            &.active {
              border-color: #fff;
            }
            &:nth-child(1) {
              margin-left: 40rem;
            }
            &:nth-last-child(1) {
              margin-right: 40rem;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              &.blur {
                filter: blur(4rem);
              }
            }
            .red {
              width: 100%;
              height: 100%;
              background: rgba(245, 94, 85, .9);
            }
            .play {
              width: 40rem;
              height: 40rem;
              position: absolute;
              top: 24rem;
              left: 24rem;
            }
            .real {
              width: 56rem;
              height: 32rem;
              background: rgba(0, 0, 0, .5);
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 6rem 12rem 6rem 6rem;
              font-size: 20rem;
              color: #fff;
              text-align: center;
              line-height: 32rem;
            }
            .fire {
              width: 28rem;
              height: 28rem;
              position: absolute;
              bottom: 8rem;
              left: 30rem;
            }
          }
        }
      }
    }
    .user-info-detail {
      position: relative;
      z-index: 1;
      padding: 40rem 32rem;
      border-radius: 32rem 32rem 0 0;
      background: #fff;
      margin-top: -32rem;
      .nickname-item {
        padding: 0 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nickname {
          font-size: 40rem;
          font-weight: 600;
          color: #000;
          line-height: 48rem;

        }
        .flex {
          flex: 1;
          padding-left: 12rem;
          img {
            width: 102rem;
            height: 34rem;
          }
        }
        .like {
          width: 48rem;
          height: 48rem;
        }
      }
      .real-item {
        margin-top: 10rem;
        margin-left: 8rem;
        width: 160rem;
        height: 40rem;
        border-radius: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24rem;
        font-weight: 500;
        color: #4a4a4a;
        background-color: #fafafa;
        img {
          width: 32rem;
          height: 32rem;
          margin-right: 4rem;
        }
      }
      .job-item {
        margin-top: 10rem;
        margin-left: 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .job {
          flex: 1;
          font-size: 24rem;
          font-weight: 500;
          color: #4a4a4a;
          line-height: 40rem;
        }
        .status {
          width: 60rem;
          height: 32rem;
          background: #e1fef4;
          border-radius: 16rem;
          text-align: center;
          line-height: 32rem;
          font-size: 20rem;
          font-weight: 500;
          color: #2bb983;
        }
        .status-not {
          padding: 0 6rem;
          height: 32rem;
          background: #e6e6e6;
          border-radius: 16rem;
          text-align: center;
          line-height: 32rem;
          font-size: 20rem;
          font-weight: 500;
          color: #7d7d7d;
        }
      }
      .jj-item {
        position: relative;
        margin-top: 60rem;
        margin-bottom: 156rem;
        padding: 40rem 24rem;
        background: linear-gradient(180deg, #fafafa 0%, #FEFFFE 100%);
        border-radius: 20rem 20rem 0 0;
        border-bottom: 1rem solid #fafafa;
        .icon {
          width: 52rem;
          height: 40rem;
          position: absolute;
          top: -20rem;
          left: 24rem;
          z-index: 1;
        }
        .jj {
          line-height: 40rem;
          color: #000;
          font-weight: 500;
          font-size: 24rem;
        }
      }
      .tag-item {
        overflow: hidden;
        .tag {
          float: left;
          margin: 20rem 12rem 0 0;
          height: 50rem;
          padding: 0 20rem 0 12rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1rem solid #e6e6e6;
          background-color: #fefffe;
          border-radius: 25rem;
          color: #7d7d7d;
          font-size: 24rem;
          font-weight: 500;
          img {
            width: 32rem;
            height: 32rem;
            margin-right: 2rem;
          }
        }
        .tags {
          padding: 12rem;
          float: left;
          margin: 20rem 12rem 0 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          border: 1rem solid #e6e6e6;
          background-color: #fefffe;
          border-radius: 50rem;
          font-size: 24rem;
          font-weight: 500;
          color: #7d7d7d;
          line-height: 32rem;
          img {
            width: 32rem;
            height: 32rem;
            margin-right: 2rem;
          }
          .name {
            width: 110rem;
          }
          .value {
            flex: 1;
          }
        }
      }
    }
  }
  .empty-container {
    padding-top: 248rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      width: 200rem;
      height: 200rem;
    }
    .info {
      margin-top: 20rem;
      font-size: 28rem;
      font-weight: 500;
      color: #a9a9a9;
      line-height: 34rem;
    }
  }
  .open-button {
    position: fixed;
    z-index: 10;
    bottom: 100rem;
    left: 50%;
    margin-left: -140rem;
    width: 280rem;
    height: 80rem;
    background: #000;
    border-radius: 40rem;
    font-size: 28rem;
    font-weight: 600;
    color: #fff;
    line-height: 80rem;
    text-align: center;
  }
}
</style>
