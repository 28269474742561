<template>
  <div class="wechat-browser-tips-container" @click="handleHideWechatBrowserTips">
    <div v-if="type == 'pay'" class="pay-container">
      <img src="../assets/images/tip-jiantou-icon.png" class="jiantou" />
      <div class="content-item">
        <div class="title-item">移步到支付宝 支付</div>
        <div class="detail-item">点击右上角的 “...”</div>
        <div class="detail-item">在“浏览器”中打开</div>
        <img src="../assets/images/tip-app-icon.png" class="icon-1" />
      </div>
    </div>
    <div v-else class="app-container">
      <img src="../assets/images/tip-jiantou-icon.png" class="jiantou" />
      <div class="content-item">
        <div class="title-item">1. 已安装 想要查看更多么？</div>
        <div class="detail-item">点击右上角的 “...”</div>
        <div class="detail-item">在“浏览器”中打开</div>
        <img src="../assets/images/tip-app-icon.png" class="icon-1" />
        <div class="title-item">2. 未安装 偶遇圈App 客户端 </div>
        <div class="detail-item">点击 “下载偶遇圈”查看更多</div>
        <img src="../assets/images/tip-xiazai-icon.png" class="icon-2" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WechatBrowserTipsComponent',
  props: {
    type: {
      type: String,
      default: 'app' // pay: 支付宝支付, app: 跳转App
    }
  },
  data () {
    return {
      isWechat: false
    }
  },
  mounted () {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') > -1) {
      this.isWechat = true
    } else {
      this.isWechat = false
    }
  },
  methods: {
    handleHideWechatBrowserTips () {
      this.$emit('handleHideWechatBrowserTips')
    }
  }
}
</script>

<style lang="less" scoped>
.wechat-browser-tips-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 999;
  .jiantou {
    position: absolute;
    top: 28rem;
    right: 22rem;
    width: 268rem;
    height: 166rem;
  }
  .pay-container {
    .content-item {
      position: absolute;
      top: 82rem;
      left: 54rem;
      color: #fff;
      .title-item {
        padding-left: 56rem;
        font-size: 40rem;
        font-weight: 600;
        line-height: 56rem;
        margin-bottom: 30rem;
      }
      .detail-item {
        padding-left: 56rem;
        font-size: 30rem;
        font-weight: 400;
        line-height: 42rem;
        margin-bottom: 12rem;
      }
      .icon-1 {
        width: 688rem;
      }
    }
  }
  .app-container {
    .content-item {
      position: absolute;
      top: 82rem;
      left: 64rem;
      color: #fff;
      .title-item {
        font-size: 40rem;
        font-weight: 600;
        line-height: 56rem;
        margin-bottom: 30rem;
      }
      .detail-item {
        padding-left: 46rem;
        font-size: 30rem;
        font-weight: 400;
        line-height: 42rem;
        margin-bottom: 12rem;
      }
      .icon-1 {
        width: 482rem;
        margin-bottom: -74rem;
        margin-left: 18rem;
      }
      .icon-2 {
        width: 628rem;
        margin-top: 28rem;
        margin-left: 28rem;
      }
    }
  }
}
</style>
